import * as React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import Grid from '@material-ui/core/Grid';
import Layout from "../components/layout"
import Seo from "../components/seo"

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class CareersPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')   
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = "Careers"
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }
    

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
        <Seo pagename={pageName +" "+ siteTitle}  keywords={keywords}/>          
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                <div className="pagesection_main">
                  There are currently no positions available in the EdgeForecaster team. We will be hiring additional Cloud Engineers, UI/UX Experts and Data Superstars
                  between March and April 2022. If you are interested in working with us and can't wait for a position to become available, please send your CV and a 
                  brief description about you and your experience to <span style={{color:"#4648ff"}}><a href="mailto:hello@edgeforecaster.com?subject=Join the EdgeForecaster Team">hello@edgeforecaster.com</a></span>
                </div>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
      
    )}
}

export default withCookies(CareersPage)
export const CareersPageQuery = graphql`
  query CareersPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl              
        }
        keywords
      }
    }
    sitePage {
        pluginCreator {
          name
        }
      }
  }
`